<!-- Don't show the top label in the window that pops up when adding item, coll, or community, which is the community name when adding an item.
<ds-truncatable-part [maxLines]="1" [background]="isCurrent() ? 'primary' : 'default'" [showToggle]="false">
  <div [ngClass]="isCurrent() ? 'text-light' : 'text-body'"
       [innerHTML]="(parentTitle$ && parentTitle$ | async) ? (parentTitle$ | async) : ('home.breadcrumbs' | translate)"></div>
</ds-truncatable-part>
-->
  <div class="font-weight-bold"
       [ngClass]="isCurrent() ? 'text-light' : 'text-primary'"
       [innerHTML]="dsoTitle"></div>
<!-- Don't show description
<ds-truncatable-part *ngIf="description" [maxLines]="1" [background]="isCurrent() ? 'primary' : 'default'" [showToggle]="false">
  <div class="text-secondary"
       [ngClass]="isCurrent() ? 'text-light' : 'text-secondary'"
       [innerHTML]="description"></div>
</ds-truncatable-part>
-->
